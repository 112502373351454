<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <PaymentMethodsReport />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import PaymentMethodsReport from '@/components/Reports/PaymentMethods/PaymentMethodsReport'

export default {
  name: 'PaymentMethods',
  components: {
    Layout,
    PaymentMethodsReport
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Relatórios',
        to: { name: 'ReportsIndex' }
      },
      {
        text: 'Formas de Pagamentos',
        to: { name: 'ReportPaymentMethods' },
        active: true
      }
    ]
  }
}
</script>
