var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('StateRenderer',_vm._b({},'StateRenderer',_vm.payments,false),[(_vm.payments.data.length === 0)?_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"secondary"}},[_vm._v(" Nenhum produto vendido neste período ")]):[_c('h4',[_c('b',[_vm._v(" Total: "+_vm._s(_vm._f("formatCurrency")(_vm.calculateTotal))+" ")])]),_c('b-table',{ref:"paymentsList",attrs:{"fields":_vm.fields,"items":_vm.payments.data,"striped":"","hover":"","busy":_vm.payments.loading,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key})})}},{key:"cell(source)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatSourceField")(item.source))+" ")])]}},{key:"cell(cash)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.getProductAmount(item, 'cash')))+" ")])]}},{key:"cell(debitCard)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.getProductAmount(item, 'debitCard')))+" ")])]}},{key:"cell(creditCard)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.getProductAmount(item, 'creditCard')))+" ")])]}},{key:"cell(pix)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.getProductAmount(item, 'pix')))+" ")])]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }